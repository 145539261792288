<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Monitoring Pasca Anesti Dan Sedasi</h3>
    <v-row justify="center">
      <v-col cols="12">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-8 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3 mb-3" />
        <div style="overflow-x: scroll">
          <table class="table" border="1">
            <thead>
            <tr>
              <th colspan="13">
                <v-text-field
                  v-model="data.form1.menyerahkan"
                  label="Perawat yang menyerahkan :"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
              <th colspan="21">
                <v-text-field
                  v-model="data.form1.menerima"
                  label="Perawat yang menerima :"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
              <th colspan="19">
                <v-text-field
                  v-model="data.form1.jam_masuk"
                  label="Jam Masuk"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th colspan="53">PEMBERIAN OBAT</th>
            </tr>
            <tr v-for="item in 3" :key="'aa'+item">
              <th colspan="13">
                <v-text-field
                  v-model="data.form1.pemberian['p'+item]"
                  :label="item + '.'"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
              <th colspan="21">
                <v-text-field
                  v-model="data.form1.pemberian['p'+(item+3)]"
                  :label="(item+3) + '.'"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
              <th colspan="19">
                <v-text-field
                  v-model="data.form1.pemberian['p'+(item+6)]"
                  :label="(item+6) + '.'"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th colspan="53">
                <v-text-field
                  v-model="data.form1.infus"
                  label="Infus/Darah & Cairan:"
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th class="p-0" style="text-align: center;vertical-align: middle" colspan="4">Time Table</th>
              <th class="p-0 pt-1" v-for="item in 49" :key="'a'+item">
                <v-text-field
                  v-model="data.form1['timetable']['p'+item]"
                  label=" "
                  dense
                  outlined
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr v-for="item in 20" :key="'b'+item">
              <th class="text-center p-0" v-if="item%2===0">{{text1[item][0]}}</th>
              <th class="text-center p-0" v-if="item%2===0">{{text1[item][1]}}</th>
              <th class="text-center p-0" v-if="item%2===0">{{text1[item][2]}}</th>
              <th class="text-center p-0" v-if="item%2===0">{{text1[item][3]}}</th>
              <th class="text-center p-0" colspan="4" v-if="item%2!==0"></th>
              <th class="p-1" v-bind:style="{ backgroundColor: data.form1[item]['p'+item2] ? 'green' : 'white' }" v-for="item2 in 49" :key="'c'+item+'-'+item2">
                <v-checkbox
                  v-model="data.form1[item]['p'+item2]"
                  label=" "
                  :value="data.form1[item]['p'+item2]"
                  :class="`mt-0 pt-0 hidecheckbox`"
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th class="text-center p-1">Suhu</th>
              <th class="text-center p-1">RR</th>
              <th class="text-center p-1">Nadi</th>
              <th class="text-center p-1">TD</th>
              <th class="p-1" v-bind:style="{ backgroundColor: data.form1['suhu']['p'+item] ? 'green' : 'white' }" v-for="item in 49" :key="'d'+item">
                <v-checkbox
                  v-model="data.form1['suhu']['p'+item]"
                  label=" "
                  :value="data.form1['suhu']['p'+item]"
                  :class="`mt-0 pt-0 hidecheckbox`"
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th colspan="4" class="text-center p-0">SpO2</th>
              <th class="p-1" v-bind:style="{ backgroundColor: data.form1['spo2']['p'+item] ? 'green' : 'white' }" v-for="item in 49" :key="'e'+item">
                <v-checkbox
                  v-model="data.form1['spo2']['p'+item]"
                  label=" "
                  :value="data.form1['spo2']['p'+item]"
                  :class="`mt-0 pt-0 hidecheckbox`"
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th colspan="4" class="text-center p-0">Urine</th>
              <th class="p-1" v-bind:style="{ backgroundColor: data.form1['urine']['p'+item] ? 'green' : 'white' }" v-for="item in 49" :key="'f'+item">
                <v-checkbox
                  v-model="data.form1['urine']['p'+item]"
                  label=" "
                  :value="data.form1['urine']['p'+item]"
                  :class="`mt-0 pt-0 hidecheckbox`"
                  hide-details="auto"
                />
              </th>
            </tr>
            <tr>
              <th colspan="4" class="text-center p-0">Pendarahan</th>
              <th class="p-1" v-bind:style="{ backgroundColor: data.form1['pendarahan']['p'+item] ? 'green' : 'white' }" v-for="item in 49" :key="'g'+item">
                <v-checkbox
                  v-model="data.form1['pendarahan']['p'+item]"
                  label=" "
                  :value="data.form1['pendarahan']['p'+item]"
                  :class="`mt-0 pt-0 hidecheckbox`"
                  hide-details="auto"
                />
              </th>
            </tr>
            </thead>
          </table>
        </div>
        <br>
        <h6>ALDRETE SCORE</h6>
        <div style="overflow-x: scroll">
          <table class="table" border="1" style="table-layout: fixed">
            <thead>
            <tr>
              <th></th>
              <th style="text-align: center;vertical-align: middle">Yang Dinilai</th>
              <th style="text-align: center;vertical-align: middle">Masuk Jam</th>
              <th style="text-align: center;vertical-align: middle">Keluar Jam</th>
              <th style="text-align: center;vertical-align: middle">Keterangan</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in 5" :key="'h'+item">
              <td style="text-align: center;vertical-align: middle">{{text2[item][0]}}</td>
              <td>
                <v-radio-group
                  v-model="data.form2[item]['p1']"
                  class="pt-0 pb-0 mt-0 mb-0"
                  hide-details="auto"
                >
                  <div v-for="(v, i) in text2[item][1]" :key="i">
                    <v-radio
                      :label="v.text + ' (' + (2-i) + ')'"
                      :value="v.value"
                    />
                  </div>
                </v-radio-group>
              </td>
              <td>
                <v-text-field
                  v-model="data.form2[item]['p2']"
                  label=" "
                  type="number"
                  dense
                  outlined
                  hide-details="auto"
                />
              </td>
              <td>
                <v-text-field
                  v-model="data.form2[item]['p3']"
                  label=" "
                  type="number"
                  dense
                  outlined
                  hide-details="auto"
                />
              </td>
              <td>
                <v-textarea
                  v-model="data.form2[item]['p4']"
                  label=" "
                  dense
                  outlined
                  hide-details="auto"
                />
              </td>
            </tr>
            <tr>
              <th style="text-align: center;vertical-align: middle" colspan="2">Jumlah</th>
              <th style="text-align: center;vertical-align: middle">{{skor2}}</th>
              <th></th>
              <th></th>
            </tr>
            </tbody>
          </table>
          <small>*Boleh pindah keruangan bila Aldrete Score : 8 - 10</small>
        </div><br>
        <h6>STEWARD SCORE</h6>
        <div style="overflow-x: scroll">
          <table class="table" border="1" style="table-layout: fixed">
            <thead>
            <tr>
              <th></th>
              <th style="text-align: center;vertical-align: middle">Penilaian</th>
              <th style="text-align: center;vertical-align: middle">Keterangan</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in 3" :key="'h'+item">
              <td style="text-align: center;vertical-align: middle">{{text3[item][0]}}</td>
              <td>
                <v-radio-group
                  v-model="data.form3[item]['p1']"
                  class="pt-0 pb-0 mt-0 mb-0"
                  hide-details="auto"
                >
                  <div v-for="(v, i) in text3[item][1]" :key="i">
                    <v-radio
                      :label="v.text"
                      :value="v.value"
                    />
                  </div>
                </v-radio-group>
              </td>
              <td>
                <v-textarea
                  v-model="data.form3[item]['p2']"
                  label=" "
                  dense
                  outlined
                  hide-details="auto"
                />
              </td>
            </tr>
            <tr>
              <th style="text-align: center;vertical-align: middle" colspan="2">Jumlah</th>
              <th style="text-align: center;vertical-align: middle">{{skor3}}</th>
            </tr>
            </tbody>
          </table>
          <small>*Boleh pindah keruangan bila skore kurang dari 5</small>
        </div><br>
        <h6>BROMAGE SCORE</h6>
        <div style="overflow-x: scroll">
          <table class="table" border="1" style="table-layout: fixed">
            <thead>
            <tr>
              <th style="text-align: center;vertical-align: middle">Kriteria</th>
              <th style="text-align: center;vertical-align: middle">Keterangan</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in 1" :key="'h'+item">
              <td>
                <v-radio-group
                  v-model="data.form4[item]['p1']"
                  class="pt-0 pb-0 mt-0 mb-0"
                  hide-details="auto"
                >
                  <div v-for="(v, i) in text4[item][1]" :key="i">
                    <v-radio
                      :label="v.text"
                      :value="v.value"
                    />
                  </div>
                </v-radio-group>
              </td>
              <td>
                <v-textarea
                  v-model="data.form4[item]['p2']"
                  label=" "
                  dense
                  outlined
                  hide-details="auto"
                />
              </td>
            </tr>
            <tr>
              <th style="text-align: center;vertical-align: middle">Jumlah</th>
              <th style="text-align: center;vertical-align: middle">{{skor4}}</th>
            </tr>
            </tbody>
          </table>
          <small>*Boleh pindah keruangan bila skore kurang dari 2</small>
        </div><br>
        <h6>Kondisi Pasien saat pindah ke ruangan</h6>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form5"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form5[i] = e)"
              :value="data.form5[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/monitoring-pasca-anesti-dan-sedasi'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      ayam: '',
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      text1: {
        2: ['41', '36', '', '180'],
        4: ['40', '32', '', '160'],
        6: ['39', '28', '180', '140'],
        8: ['38', '24', '160', '120'],
        10: ['37', '20', '140', '100'],
        12: ['36', '16', '120', '80'],
        14: ['35', '12', '100', '60'],
        16: ['34', '8', '80', '40'],
        18: ['33', '', '60', '20'],
        20: ['32', '', '40', '0']
      },
      text2: {
        1: ['Kesadaran', [
          { text: 'Sadar penuh', value: '2' },
          { text: 'Bangun bila dipanggil', value: '1' },
          { text: 'Tidak bangun bila dipanggil', value: '0' }
        ]],
        2: ['Motorik', [
          { text: 'Bisa mengangkat 4 ekskremitas', value: '2' },
          { text: 'Bisa mengangkat 2 ekskremitas', value: '1' },
          { text: 'Tidak bisa mengangkat ekskremitas', value: '0' }
        ]],
        3: ['Tensi', [
          { text: 'Sistole ±20 Pre Anestesi', value: '2' },
          { text: 'Sistole ±20 - 50', value: '1' },
          { text: '>50', value: '0' }
        ]],
        4: ['Pernafasan', [
          { text: 'Bisa bernafas dalam, tidak ada halangan', value: '2' },
          { text: 'Dispnea, dangkal', value: '1' },
          { text: 'Apnea', value: '0' }
        ]],
        5: ['Warna Kulit', [
          { text: 'Kulit kemerahan', value: '2' },
          { text: 'Kulit pucat', value: '1' },
          { text: 'Biru', value: '0' }
        ]]
      },
      text3: {
        1: ['Kesadaran', [
          { text: '1. Bangun', value: '1' },
          { text: '2. Respon terhadap rangsangan', value: '2' },
          { text: '3. Tidak ada respon', value: '3' }
        ]],
        2: ['Motorik', [
          { text: '1. batuk/menangis', value: '1' },
          { text: '2. Pertahankan Jalan Nafas', value: '2' },
          { text: '3. Perlu bantuan nafas', value: '3' }
        ]],
        3: ['Tensi', [
          { text: '1. Gerak bertujua', value: '1' },
          { text: '2. Gerak tanpa tujuan', value: '2' },
          { text: '3. Tidak bergerak', value: '3' }
        ]]
      },
      text4: {
        1: ['', [
          { text: 'Dapat mengankat tungkai bawah', value: '0' },
          { text: 'Tidak dapat menekuk lutut tetapi dapat mengangkat kaki', value: '1' },
          { text: 'Tidak dapat mengangkat tungkai bawah tetapi masih dapat menekuk lutut', value: '2' },
          { text: 'Tidak dapat mengangkat kaki sama sekali', value: '3' }
        ]]
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          menyerahkan: '',
          menerima: '',
          jam_masuk: '',
          pemberian: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: ''
          },
          infus: '',
          timetable: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          suhu: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          spo2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          urine: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          pendarahan: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          6: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          7: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          8: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          9: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          10: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          11: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          12: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          13: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          14: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          15: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          16: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          17: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          18: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          19: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          },
          20: {
            p1: '',
            p2: '',
            p3: '',
            p4: '',
            p5: '',
            p6: '',
            p7: '',
            p8: '',
            p9: '',
            p10: '',
            p11: '',
            p12: '',
            p13: '',
            p14: '',
            p15: '',
            p16: '',
            p17: '',
            p18: '',
            p19: '',
            p20: '',
            p21: '',
            p22: '',
            p23: '',
            p24: '',
            p25: '',
            p26: '',
            p27: '',
            p28: '',
            p29: '',
            p30: '',
            p31: '',
            p32: '',
            p33: '',
            p34: '',
            p35: '',
            p36: '',
            p37: '',
            p38: '',
            p39: '',
            p40: '',
            p41: '',
            p42: '',
            p43: '',
            p44: '',
            p45: '',
            p46: '',
            p47: '',
            p48: '',
            p49: ''
          }
        },
        form2: {
          1: {
            p1: '',
            p2: '',
            p3: '',
            p4: ''
          },
          2: {
            p1: '',
            p2: '',
            p3: '',
            p4: ''
          },
          3: {
            p1: '',
            p2: '',
            p3: '',
            p4: ''
          },
          4: {
            p1: '',
            p2: '',
            p3: '',
            p4: ''
          },
          5: {
            p1: '',
            p2: '',
            p3: '',
            p4: ''
          },
          skor: 0
        },
        form3: {
          1: {
            p1: '',
            p2: ''
          },
          2: {
            p1: '',
            p2: ''
          },
          3: {
            p1: '',
            p2: ''
          },
          skor: 0
        },
        form4: {
          1: {
            p1: '',
            p2: ''
          },
          skor: 0
        },
        form5: {}
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
    skor2: function () {
      var hmm = this
      var x = this.data.form2
      const sum = parseInt(x[1].p1 ? x[1].p1 : 0) + parseInt(x[2].p1 ? x[2].p1 : 0) + parseInt(x[3].p1 ? x[3].p1 : 0) + parseInt(x[4].p1 ? x[4].p1 : 0) + parseInt(x[5].p1 ? x[6].p1 : 0)
      if (isNaN(sum)) {
        hmm.data.form2.skor = 0
        return 0
      } else {
        hmm.data.form2.skor = sum
        return sum
      }
    },
    skor3: function () {
      var hmm = this
      var x = this.data.form3
      const sum = parseInt(x[1].p1 ? x[1].p1 : 0) + parseInt(x[2].p1 ? x[2].p1 : 0) + parseInt(x[3].p1 ? x[3].p1 : 0)
      if (isNaN(sum)) {
        hmm.data.form3.skor = 0
        return 0
      } else {
        hmm.data.form3.skor = sum
        return sum
      }
    },
    skor4: function () {
      var hmm = this
      var x = this.data.form4
      const sum = parseInt(x[1].p1 ? x[1].p1 : 0)
      if (isNaN(sum)) {
        hmm.data.form4.skor = 0
        return 0
      } else {
        hmm.data.form4.skor = sum
        return sum
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>

.hidecheckbox{
  transform: scale(1.5);
  opacity: 0;
}
.wrap {
  width: 100%;
  height: 690px;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
