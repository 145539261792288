export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form5: {
    p1: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'TD'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Suhu'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Aldrete Score'
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Skor Nyeri'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'HR'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'SpO2'
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Steward score'
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Jam Keluar'
    },
    p9: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'RR'
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'GCS'
    },
    p11: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Bromage Score'
    }
  }
}
